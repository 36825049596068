


//-----------------  The input show / hide password -----------------//
function togglePassword(params) {

    const togglePassword = document.querySelector('#togglePassword');
    const password = document.querySelector('#password');
    togglePassword.addEventListener('click', function (e) {
        // toggle the type attribute
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        // toggle the eye slash icon
        this.classList.toggle('fa-eye-slash');
    });
}

// ngOnInit()
// {
//   //-----------------  The input show / hide password -----------------//
//   const togglePassword3 = document.querySelector('#togglePassword3');
//   const password3 = document.querySelector('#password3');


//   togglePassword3.addEventListener('click', function (e) {
//   // toggle the type attribute
//   const type = password3.getAttribute('type') === 'password' ? 'text' : 'password';
//   password3.setAttribute('type', type);
//   // toggle the eye slash icon
//   this.classList.toggle('fa-eye-slash');
//   });

//   //-----------------  The input show / hide password -----------------//
// }









