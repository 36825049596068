/** Init Affter Load Javascript 
 * Call In App.Component.ts
*/

function initGoToTop() {
	var scroll = $(window).scrollTop();
	if (scroll > 200) {

		$(".back-to-top-button").addClass("button-up-active");

	} else {

		$(".back-to-top-button").removeClass('button-up-active');
	}
	// All animations will take exactly 500ms
	var smoothScroll = new SmoothScroll('#goToTop', {
		speed: 500,
		speedAsDuration: true
	});
}

